<template>
  <div class="services">
    <div
      class="services__content col-11 col-sm-10"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-offset="300"
    >
      <div class="services__content__title">{{ title }}</div>
      <div class="services__content__sub">{{ sub }}</div>
    </div>
    <div class="services__container">
      <div class="services__items col-11 col-sm-10">
        <router-link
          class="services__item overflow-hidden"
          v-for="(item, index) in service"
          :key="index"
          :to="`/projects?category=${item.category}`"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="300"
        >
          <div
            class="position-relative flex justify-content-center align-items-center w-100"
          >
            <img v-lazy="item.bg" class="services__item__img" alt="services" />
            <div class="view-project d-none d-sm-flex">{{ button }}</div>
          </div>
          <p
            class="text-dark pt-2 pt-sm-3 m-0 font-weight-bold sm-h5 text-center w-75"
          >
            {{ item.title }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Services',
  data() {
    return {
      arrow: '/images/icons/home/arrow-right.svg',
      cards: this.$t('home').service.cards,
      title: this.$t('home').service.title,
      sub: this.$t('home').service.sub,
      button: this.$t('home').service.button,
      service: [
        {
          bg: '/images/home/services_1_1.webp',
        },
        {
          bg: '/images/home/services_2_1.webp',
        },
        {
          bg: '/images/home/services_3_1.webp',
        },
        {
          bg: '/images/home/services_4.webp',
        },
        {
          bg: '/images/home/services_5_1.webp',
        },
        {
          bg: '/images/home/services_6_1.webp',
        },
      ],
    };
  },
  mounted() {
    this.service = this.service.map((item, indx) => ({
      ...item,
      title: this.cards[indx].title,
      content: this.cards[indx].content,
      category: this.cards[indx].category.replace(' ', ''),
    }));
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  watch: {
    lang() {
      this.cards = this.$t('home').service.cards;
      this.title = this.$t('home').service.title;
      this.sub = this.$t('home').service.sub;
      (this.button = this.$t('home').service.button),
        (this.service = this.service.map((item, indx) => ({
          ...item,
          title: this.cards[indx].title,
          content: this.cards[indx].content,
          category: this.cards[indx].category,
        })));
    },
  },
};
</script>

<style lang="scss" scroped>
img {
  object-fit: cover;
}
.services {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 100px;
  width: 100%;
  padding: 50px 0;
}
.services__content {
  margin: auto;
  text-align: center;
}
.services__content__title {
  position: relative;
  font-size: 40px;
  font-weight: 700;
  color: var(--blue-text);
  z-index: 0;
  margin-bottom: 16px;
  &::after {
    content: '';
    position: absolute;
    background-color: #ffe7db;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    z-index: -1;
    left: 50%;
    transform: translateX(-232%);
  }
}
.services__content__sub {
  color: var(--grey-text);
  font-size: 20px;
  max-width: 60%;
  margin: 0 auto;
  font-weight: 500;
}
.services__container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.services__items {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 32px 8%;
  margin: auto;
  z-index: 0;
}
.services__item {
  text-decoration: none !important;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.5s alternate;
  overflow: hidden;
  p {
    font-size: 20px;
  }
  .services__item__img {
    object-fit: cover;
    width: 100%;
    /* height: 400px; */
    aspect-ratio: 1 / 1;
    transition: all 0.5s;
    border-radius: 10px;
  }
  .view-project {
    display: flex;
    position: absolute;
    width: 110px;
    height: 110px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    background-color: white;
    color: #fc7735;
    border-radius: 100%;
    font-weight: 600;
    transition: all 0.5s;
    opacity: 0;
  }
  &:hover {
    p {
      color: #fc7735 !important;
      transition: all 0.2s linear;
    }
    img {
      filter: brightness(70%);
    }
    .view-project {
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
}

@media (max-width: 1024px) {
  .services {
    padding-top: 50px;
  }
  .services__items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
    width: 90%;
  }
  /* .services__item {
    height: 300px;
  } */
}
@media (max-width: 767.98px) {
  .services__items {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    width: 80%;
  }
  .services {
    padding-top: 50px;
  }
  .services__content__title {
    font-size: 32px;
  }
  .services__content__sub {
    font-size: 16px;
    max-width: 100%;
  }
  .services__content {
    width: 80%;
  }
  .services__item {
    border-radius: 12px;
    p {
      color: #fc7735 !important;
      border-radius: 12px;
    }
  }
  .services__item__img {
    width: 100% !important;
    height: 190px !important;
    object-fit: cover;
    border-radius: 12px;
  }
}
</style>
